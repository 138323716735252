<template>
  <div class="xinjiangPage">
    <div class="navClass">
      <NavDemo title="新疆震害与救援视界" style="height: 100%" />
    </div>
    <div class="staticClass">
      <!-- 中国地图 -->
      <div class="china-map">
        <centerMap />
      </div>
      <!-- 折线图 -->
      <div class="line_center">
        <dv-border-box-8>
          <div id="line_center_diagram"></div>
        </dv-border-box-8>
      </div>
    </div>
    <div class="leftClass">
      <div class="left_box">
        <dv-border-box-12 style="padding-top: 10px">
          <div class="tlt">2000-2023地震震级划分</div>
          <div id="Rose_diagram"></div>
          <!-- 4个主要的数据 -->
          <div class="rose_box">
            <div
              class="rose_text"
              v-for="(item, index) in numberData"
              :key="index"
            >
              <div class="rose_text_nmb">{{ item.number.number }}</div>
              <div>{{ item.text }}</div>
            </div>
          </div>
        </dv-border-box-12>
      </div>
      <div class="left_box">
        <dv-border-box-12 style="padding-top: 10px">
          <div class="tlt">2014-2023地震每年发生次数</div>
          <div id="columnar"></div>
        </dv-border-box-12>
      </div>
      <div class="left_box">
        <dv-border-box-12 style="padding-top: 10px">
          <div class="tlt">2000-2023地震死亡人数TOP5</div>

          <dv-scroll-board
            :config="board_info1"
            class="carousel_list"
            oddRowBGC="#fff"
          />
        </dv-border-box-12>
      </div>
    </div>

    <div class="rightClass">
      <div class="right_box">
        <dv-border-box-12>
          <dv-decoration-7 style="width: 100%; height: 30px"
            >2000-2023地震震级top5</dv-decoration-7
          >
          <dv-scroll-ranking-board
            :config="config1"
            style="width: 95%; height: 87%; margin-left: 2%"
          />
        </dv-border-box-12>
      </div>
      <!-- 虚线柱状图部分 -->
      <div class="right_box">
        <dv-border-box-12 style="padding-top: 10px">
          <div class="tlt">2014-2023震级趋势</div>
          <div id="dotter_bar"></div>
        </dv-border-box-12>
      </div>
      <!-- 部分 -->
      <div class="right_box">
        <dv-border-box-12 style="padding-top: 10px">
          <div class="tlt">2014-2023深度趋势</div>
          <dv-conical-column-chart :config="cone1" class="cone_box" />
        </dv-border-box-12>
      </div>
    </div>
  </div>
</template>

<script>
//import drawMixin from "@/utils/drawMixin"; //自适应缩放
//import Left1 from './left1'
import * as echarts from "echarts";
import centerMap from "./center";
import NavDemo from "@/components/FrmHeader.vue";
import {
  magDivide,
  timeeveryyear,
  magtop5,
  deathtop6,
  yearszoushi,
  yearsDepth,
  yearstimes,
} from "../../axios/api/LargeScreen";

export default {
  //mixins: [drawMixin],
  data() {
    return {
      arr: [],
      left2: [],
      year: [],
      year1: [],
      count1: [],
      formattedData: [],
      left1: [],
      count: [],
      timing: null,
      loading: true,
      //轮播排行榜
      config: {
        data: [],
      },
      //左侧饼图文字
      numberData: [
        {
          number: {
            number: 3.0,
          },
          text: "最弱地震",
        },
        {
          number: {
            number: 8.1,
          },
          text: "最强地震",
        },
        {
          number: {
            number: 109,
          },
          text: "最多人口伤亡",
        },
        {
          number: {
            number: 5,
          },
          text: "最少人口伤亡",
        },
      ],
      //左侧轮播表格配置项
      // 定义颜色
      colorList: {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)",
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)",
            },
          ],
        },
      },
      //锥形柱状图
    };
  },
  computed: {
    board_info1: {
      get() {
        const result = {
          header: ["地区", "年份", "人数"],
          data: [],
          evenRowBGC: "#020308",
          oddRowBGC: "#382B47",
          headerBGC: "#020308",
        };
        result.data = this.formattedData.map((item, index) => {
          return [ item.name,item.year, Math.floor(item.death).toString()];
        });
        return result;
      },
    },
    config1: {
      get() {
        const result = {
          data: {},
        };
        console.log(this.left1, 90);
        result.data = this.left1.map((item, index) => {
          return {
            name: item.earthquakeName+"("+item.earthquakeTime.substring(0, 4)+"年)",
            value: item.magnitude.toString(),
          };
        });
        return result;
      },
    },
    cone1: {
      get() {
        const result = {
          data: [],
          showValue: true,
        };
        result.data = this.left2.map((item) => {
          return {
            name: item.year,
            value: parseFloat(item.count.toFixed(1)), // 使用年份对应的 count 数据
          };
        });

        result.data.sort((a, b) => a.name - b.name);
        console.log(result.data);
        return result;
      },
    },
  },
  components: {
    centerMap,
    NavDemo,
  },
  mounted() {
    //左侧玫瑰饼图
    this.Rose_diagram();
    //左侧柱状图
    this.columnar();
    //中间折线图
    this.line_center_diagram();
    //虚线柱状图
    this.dotter_bar();
  },
  created() {
    this.Datalist();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },

  methods: {
    //排行榜：
    async Datalist() {
      await magtop5()
        .then((res) => {
          this.formattedData = res.data;
        })
        .catch((err) => {});
      await deathtop6()
        .then((res) => {
          this.left1 = res.data;
        })
        .catch((err) => {});
      await yearsDepth()
        .then((res) => {
          this.left2 = res.data;
        })
        .catch((err) => {});
    },
    //玫瑰饼图
    async Rose_diagram() {
      let mapChart1 = this.$echarts.init(
        document.getElementById("Rose_diagram")
      ); //图表初始化，china-map是绑定的元素
      await magDivide()
        .then((res) => {
          this.arr = res.data;
        })
        .catch((err) => {});
      window.onresize = mapChart1.resize; //如果容器变大小，自适应从新构图
      let option = {
        color: [
          "#37a2da",
          "#32c5e9",
          "#9fe6b8",
          "#ffdb5c",
          "#ff9f7f",
          "#fb7293",
          "#e7bcf3",
          "#8378ea",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        calculable: true,
        legend: {
          orient: "vertical", //"horizontal",
          icon: "circle",
          //bottom: 5,
          //right: 'right',
          //x: "center",
          type: "scroll",
          right: 10,
          top: "middle",
          data: ["2-3", "3-4", "4-5", "5-6", "6-7", "7-9"],
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            name: "地震震级划分",
            type: "pie",
            radius: [10, 60],
            roseType: "area",
            center: ["50%", "40%"],
            data: this.arr,
          },
        ],
      };
      mapChart1.setOption(option); //生成图表
    },
    //柱状图
    async columnar() {
      let mapChart2 = this.$echarts.init(document.getElementById("columnar")); //图表初始化，china-map是绑定的元素
      window.onresize = mapChart2.resize; //如果容器变大小，自适应从新构图
      await timeeveryyear()
        .then((res) => {
          console.log(res.data, 39);
          res.data.sort((a, b) => a.year - b.year);
          res.data.forEach((e) => {
            this.year.push(String(e.year));
            this.count.push(e.count);
          });
        })
        .catch((err) => {});
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC",
            },
          },
        },
        legend: {
          data: ["已贯通", "计划贯通", "贯通率"],
          textStyle: {
            color: "#B4B4B4",
          },
          top: "0%",
        },
        grid: {
          left: 50,
          right: 20,
          bottom: 20,
          top: 10,
        },
        xAxis: {
          data: this.year,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },

            axisLabel: {
              formatter: "{value} ",
            },
          },
        ],
        series: [
          {
            //name: "次数",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#956FD4" },
                  { offset: 1, color: "#3EACE5" },
                ]),
              },
            },
            data: this.count,
          },
          // {
          //   name: "计划贯通",
          //   type: "bar",
          //   barGap: "-100%",
          //   barWidth: 10,
          //   itemStyle: {
          //     normal: {
          //       barBorderRadius: 5,
          //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //         { offset: 0, color: "rgba(156,107,211,0.8)" },
          //         { offset: 0.2, color: "rgba(156,107,211,0.5)" },
          //         { offset: 1, color: "rgba(156,107,211,0.2)" },
          //       ]),
          //     },
          //   },
          //   z: -12,
          //   data: [
          //     180, 207, 240, 283, 328, 360, 398, 447, 484, 504, 560, 626, 595,
          //     675, 180, 207, 240, 283, 328, 360, 398, 447, 484, 504, 360, 398,
          //     447, 484, 504, 500, 326, 495, 328,
          //   ],
          // },
        ],
      };
      mapChart2.setOption(option); //生成图表
    },
    //折线图
    async line_center_diagram() {
      let mapChart3 = this.$echarts.init(
        document.getElementById("line_center_diagram")
      ); //图表初始化，china-map是绑定的元素
      window.onresize = mapChart3.resize; //如果容器变大小，自适应从新构图
      let year = [];
      let count = [];
      await yearstimes()
        .then((res) => {

          res.data.sort((a, b) => a.year - b.year);
          res.data.forEach((e) => {
            year.push(e.year);
            count.push(e.count);
          });
        })
        .catch((err) => {});
      let option = {
        xAxis: {
          type: "category",
          data: year,
          position: "bottom",
          axisLine: true,
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12,
          },
        },
        yAxis: {
          type: "value",
          name: "2014-2023震级",
          nameLocation: "end",
          nameGap: 24,
          nameTextStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: 14,
          },
          splitNumber: 4,
          axisLine: {
            lineStyle: {
              opacity: 0,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.1,
            },
          },
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12,
          },
        },
        grid: {
          left: 50,
          right: 10,
          bottom: 25,
          top: "18%",
        },
        series: [
          {
            data: count,
            type: "line",
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#fff",
              },
            },
            //线的颜色样式
            lineStyle: {
              normal: {
                color: this.colorList.linearBtoG,
                width: 3,
              },
            },
            //填充颜色样式
            areaStyle: {
              normal: {
                color: this.colorList.areaBtoG,
              },
            },
          },
        ],
      };
      mapChart3.setOption(option); //生成图表
    },
    //右侧虚线柱状图
    async dotter_bar() {
      let mapChart4 = this.$echarts.init(document.getElementById("dotter_bar")); //图表初始化，china-map是绑定的元素
      window.onresize = mapChart4.resize; //如果容器变大小，自适应从新构图
      await yearszoushi()
        .then((res) => {
          res.data.forEach((e) => {
            this.year1.push(e.year);
            this.count1.push(Math.round(e.count));
            console.log(this.year1, this.count1, 909);
          });
        })
        .catch((err) => {});
      // option
      let option = {
        tooltip: {
          show: false,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: 50,
          right: 20,
          bottom: 25,
          top: 10,
        },
        legend: {
          data: ["line", "bar"],
          textStyle: {
            color: "#ccc",
          },
        },
        xAxis: {
          data: this.year1,
          axisLine: {
            lineStyle: {
              color: "#ccc",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          splitLine: { show: false },
          axisLine: {
            lineStyle: {
              color: "#ccc",
            },
          },
        },
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            //symbol: "circle", //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "#058cff",
            },
            data: this.count1,
          },
          {
            name: "",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            itemStyle: {
              // color: "rgba(20,200,212,0.5)",
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(20,200,212,0.5)" },
                { offset: 0.2, color: "rgba(20,200,212,0.2)" },
                { offset: 1, color: "rgba(20,200,212,0)" },
              ]),
            },
            z: -12,
            data: this.count1,
          },
        ],
      };
      mapChart4.setOption(option); //生成图表
    },
  },
  destroyed() {
    window.onresize = null;
  },
};
</script>

<style lang="scss" scoped>
.xinjiangPage {
  height: 100%;
  width: 100%;
  background-image: url("../../../public/img/stars.jpg");
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  color: #d3d6dd;

  .navClass {
    position: fixed;
    top: 0;
    height: 10%;
    width: 100%;
    z-index: 5;
  }

  .tlt {
    font-size: 14px;
    padding: 5px 15px 10px;
  }

  .staticClass {
    position: absolute;
    top: 10%;
    bottom: 10px;
    right: calc(25% + 20px);
    left: calc(25% + 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    //中国地图
    .china-map {
      height: 70%;
      width: 100%;
    }
    //中间折线图
    .line_center {
      width: 100%;
      height: 30%;
    }
    //折线图
    #line_center_diagram {
      height: 100%;
      width: 100%;
    }
  }
  .leftClass {
    position: absolute;
    left: 20px;
    top: 10%;
    //height: 90%;
    bottom: 10px;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    //左1模块
    .left_box {
      height: 33%;
      width: 100%;
    }

    //左1模块-玫瑰饼图
    #Rose_diagram {
      height: calc(100% - 100px);
      width: 96%;
    }
    .rose_box {
      width: 100%;
      height: 50px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
    }
    //左1模块-文字部分
    .rose_text {
      display: inline-block;
      font-size: 14px;
      //margin-top: 4%;
      //margin-left: 4%;
    }
    //左1模块-数字样式
    .rose_text_nmb {
      font-size: 20px;
      font-weight: bold;
      color: #00b891;
    }
    //左2模块 柱状图
    #columnar {
      height: calc(100% - 50px);
      width: 96%;
    }
    //轮播表格
    .carousel_list {
      width: 96%;
      height: calc(100% - 40px);
      margin-left: 10px;
    }
  }

  .rightClass {
    position: absolute;
    top: 10%;
    right: 20px;
    bottom: 10px;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    //右1模块
    .right_box {
      height: 33%;
      width: 100%;
    }

    //虚线柱状图
    #dotter_bar {
      width: 100%;
      height: calc(100% - 50px);
    }
    //锥形图
    .cone_box {
      width: 93%;
      height: calc(100% - 60px);
      margin-left: 3%;
      font-size: 12px;
    }
  }
}
</style>
