<template>
  <div id="centreChart" class="mapChart"></div>
</template>

<script>
import echartMixins from "@/utils/resizeMixin";
import '@/components/echart/js/xinjiang.js'
export default {
data() {
  return {
    chart: null,
    cdata: [
      {
        // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
        name: '乌鲁木齐市',
        value: 10,
        elseData:{
          // 这里放置地图 tooltip 里想显示的数据
        }
      },
      {
        name: '克拉玛依市',
        value: 9,
      },
      {
        name: '吐鲁番市',
        value: 8,
      },
      {
        name: '昌吉回族自治州',
        value: 7,
      },
      {
        name: '巴音郭楞蒙古自治州',
        value: 6,
      },
      {
        name: '阿克苏地区',
        value: 5,
      },
      {
        name: '克孜勒苏柯尔克孜自治州',
        value: 4,
      },
      {
        name: '博尔塔拉蒙古自治州',
        value: 3,
      },
      {
        name: '喀什地区',
        value: 2,
      },
      {
        name: '伊犁哈萨克自治州',
        value: 2,
      },
      {
        name: '塔城地区',
        value: 2,
      },
      {
        name: '阿勒泰地区',
        value: 2,
      },
      {
        name: '石河子市',
        value: 2,
      },
      {
        name: '阿拉尔市',
        value: 2,
      },

      {
        name: '图木舒克市',
        value: 3,
      },
      {
        name: '五家渠市',
        value: 2,
      },
      {
        name: '北屯市',
        value: 2,
      },
      {
        name: '铁门关市',
        value: 2,
      },
      {
        name: '双河市',
        value: 2,
      },
      {
        name: '可克达拉市',
        value: 2,
      },
      {
        name: '和田地区',
        value: 2,
      },
      {
        name: '哈密市',
        value: 2,
      },

    ]
  };
},
mixins: [echartMixins],
mounted() {
  this.draw();
},
methods: {
  draw() {

    const geoCoordMap = {
        克拉玛依市: [84.873946,45.595886, 20],
        乌鲁木齐市: [87.617733,43.792818, 20],
        昌吉回族自治州: [87.304012,44.014577, 20],
        吐鲁番市: [89.184078,42.947613, 20],
        博尔塔拉蒙古自治州: [82.074778,44.903258, 20],
        阿克苏地区: [80.265068,41.170712, 20],
        巴音郭楞蒙古自治州: [86.150969,41.768552, 20],
        克孜勒苏柯尔克孜自治州: [76.172825,39.713431, 20],
        喀什地区: [75.989138,39.467664, 20],
        和田地区: [79.92533,37.110687, 20],
        伊犁哈萨克自治州:[81.317946,43.92186,20],
        塔城地区:[82.985732,46.746301,20],
        阿勒泰地区:[88.13963,47.848393,20],
        石河子市:[86.041075,44.305886,20],
        阿拉尔市:[81.285884,40.541914,20],

        图木舒克市: [79.077978,39.867316, 20],
        五家渠市:[87.526884,44.167401,20],
        北屯市:[87.824932,47.353177,20],
        铁门关市:[85.501218,41.827251,20],
        双河市:[82.353656,44.840524,20],
        可克达拉市:[80.63579,43.6832,20],
      };
      let seriesData = [
        {
          name: '克拉玛依市',
        },
        {
          name: '乌鲁木齐市',
        },
        {
          name: '昌吉回族自治州',
        },
        {
          name: '吐鲁番市',
        },
        {
          name: '博尔塔拉蒙古自治州',
        },
        {
          name: '阿克苏地区',
        },
        {
          name: '巴音郭楞蒙古自治州',
        },
        {
          name: '克孜勒苏柯尔克孜自治州',
        },
        {
          name: '喀什地区',
        },
        {
          name:"伊犁哈萨克自治州",
        },
        {
          name:"塔城地区"
        },
        {
          name: '阿勒泰地区',
        },
        {
          name:"石河子市",
        },
        {
          name:"阿拉尔市"
        },
        {
        name: '图木舒克市',
      },
      {
        name: '五家渠市',
      },
      {
        name: '北屯市',
      },
      {
        name: '铁门关市',
      },
      {
        name: '双河市',
      },
      {
        name: '可克达拉市',
      },
      {
        name: '和田地区',
      },
      {
        name: '哈密市',
      },
      ];
      let convertData = function (data) {
        let scatterData = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            scatterData.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        return scatterData;
      };

    // 基于准备好的dom，初始化echarts实例
    this.chart = this.$echarts.init(document.getElementById("centreChart"));
    //  ----------------------------------------------------------------

    this.chart.setOption({
      showLegendSymbol: true,
        tooltip: {
          trigger: 'item',
          textStyle: {
            fontSize: 14,
            lineHeight: 22,
          },
          position: point => {
            // 固定在顶部
            return [point[0] + 50, point[1] - 20];
          },
          // 如果需要自定义 tooltip样式，需要使用formatter
          /*
            formatter: params => {
              return `<div style=""> ... </div>`
            }
          */
        },
        visualMap: {
          min: 0,
          max: 10,
          show: false,
          seriesIndex: 0,
          // 颜色
          inRange: {
            color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
          },
        },
        // 底部背景
        geo: {
          show: true,
          aspectScale: 0.85, //长宽比
          zoom: 1.2,
          top: '10%',
          left: '13%',
          map: '新疆',
          roam: false,
          itemStyle: {
            normal: {
              areaColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(7,114,204, .8)',
              shadowOffsetX: 5,
              shadowOffsetY: 5,
            },
            emphasis: {
              areaColor: '#00aeef',
            },
          },
        },
        series: [
          {
            name: '相关指数',
            type: 'map',
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            mapType: '新疆', // 自定义扩展图表类型
            top: '10%',
            left: '13%',
            itemStyle: {
              normal: {
                color: 'red',
                areaColor: 'rgba(19,54,162, .5)',
                borderColor: 'rgba(0,242,252,.3)',
                borderWidth: 1,
                shadowBlur: 7,
                shadowColor: '#00f2fc',
              },
              emphasis: {
                areaColor: '#4f7fff',
                borderColor: 'rgba(0,242,252,.6)',
                borderWidth: 2,
                shadowBlur: 10,
                shadowColor: '#00f2fc',
              },
            },
            label: {
              formatter: params => `${params.name}`,
              show: true,
              position: 'insideRight',
              textStyle: {
                fontSize: 14,
                color: '#efefef',
              },
              emphasis: {
                textStyle: {
                  color: '#fff',
                },
              },
            },
            //data: this.cdata,
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            symbolSize: 7,
            effectType: 'ripple',
            legendHoverLink: false,
            showEffectOn: 'render',
            rippleEffect: {
              period: 4,
              scale: 2.5,
              brushType: 'stroke',
            },
            zlevel: 1,
            itemStyle: {
              normal: {
                color: '#99FBFE',
                shadowBlur: 5,
                shadowColor: '#fff',
              },
            },
            data: convertData(seriesData),
          },
        ],
    });
    
     // 重新选择区域
     //this.handleMapRandomSelect();
  },
  // 开启定时器
  startInterval() {
    const _self = this;
    // 应通过接口获取配置时间，暂时写死5s
    const time = 2000;
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      _self.reSelectMapRandomArea();
    }, time);
  },
  // 重新随机选中地图区域
  reSelectMapRandomArea() {
    const length = 9;
    this.$nextTick(() => {
      try {
        const map = this.$refs.centreLeft2ChartRef.chart;
        let index = Math.floor(Math.random() * length);
        while (index === this.preSelectMapIndex || index >= length) {
          index = Math.floor(Math.random() * length);
        }
        map.dispatchAction({
          type: 'mapUnSelect',
          seriesIndex: 0,
          dataIndex: this.preSelectMapIndex,
        });
        map.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index,
        });
        map.dispatchAction({
          type: 'mapSelect',
          seriesIndex: 0,
          dataIndex: index,
        });
        this.preSelectMapIndex = index;
      } catch (error) {
        console.log(error)
      }
    });
  },
  handleMapRandomSelect() {
    this.$nextTick(() => {
      try {
        const map = this.$refs.centreLeft2ChartRef.chart;
        const _self = this;
        setTimeout(() => {
          _self.reSelectMapRandomArea();
        }, 0);
        // 移入区域，清除定时器、取消之前选中并选中当前
        map.on('mouseover', function (params) {
          clearInterval(_self.intervalId);
          map.dispatchAction({
            type: 'mapUnSelect',
            seriesIndex: 0,
            dataIndex: _self.preSelectMapIndex,
          });
          map.dispatchAction({
            type: 'mapSelect',
            seriesIndex: 0,
            dataIndex: params.dataIndex,
          });
          _self.preSelectMapIndex = params.dataIndex;
        });
        // 移出区域重新随机选中地图区域，并开启定时器
        map.on('globalout', function () {
          _self.reSelectMapRandomArea();
          _self.startInterval();
        });
        this.startInterval();
      } catch (error) {
        console.log(error)
      }
    });
  },


},
destroyed() {
  window.onresize = null;
}
};
</script>

<style lang="scss" scoped>
.mapChart{
width: 100%;
height: 100%;
}
</style>